import React, { useEffect } from 'react';
import About from './Company/About';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Home from './Home/Home';
import {Routes, Route, useLocation} from 'react-router-dom'
import Partnerships from './Company/Partnerships';
import Career from './Company/Career';
import Locations from './Company/Locations';
import AIML from './Solutions/AIML';
import Events from './Company/Events';
import PagenotFound from './Home/PagenotFound';
import Downloads from './Company/Downloads';
import Storage from './Solutions/Storage';
import AnalogMixedSignal from './Solutions/AnalogMixedSignal';
import Analog from './Services/Analog';
import Digital from './Services/Digital';
import Fpga from './Services/Fpga';
import PSA from './Tools/PSA';
import Leadership from './Company/Leadership';
import Gallery from './Company/Gallery';
import Symbiosys from './Company/symbiosys';
import GSA from './Company/GSA';
import AcademiaReltion from './Company/AcademiaReltion';
import Membership from './Company/Membership';
import LeadershipThought from './Insights/LeadershipThought';
import News from './Insights/news';
import Products from './Tools/Products';
import Corplink from './Tools/Corplink';
import Sales from './Tools/Sales';

function App() {  

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header/>
        <link rel="icon" href="public/favicon.ico" />
        <Routes>
          <Route exact path="/" element={<Home/>}></Route>
          <Route exact path="/Home" element={<Home/>}></Route>
          <Route exact path="/About" element={<About/>}></Route>
          <Route exact path="/Career" element={<Career/>}></Route>
          <Route exact path="/Events" element={<Events/>}></Route>
          <Route exact path="/Locations" element={<Locations/>}></Route>
          <Route exact path="/Partnerships" element={<Partnerships/>}></Route>
          <Route exact path="/Downloads" element={<Downloads/>}></Route>
          <Route exact path="/AIML" element={<AIML/>}></Route>
          <Route exact path="/Storage" element={<Storage/>}></Route>
          <Route exact path="/AnalogMixedSignal" element={<AnalogMixedSignal/>}></Route>
          <Route exact path="/Analog" element={<Analog/>}></Route>
          <Route exact path="/Digital" element={<Digital/>}></Route>
          <Route exact path="/Fpga" element={<Fpga/>}></Route>
          <Route exact path="/PSA" element={<PSA/>}></Route>
          <Route exact path="/Leadership" element={<Leadership/>}></Route>
          <Route exact path="/Gallery" element={<Gallery/>}></Route>
          <Route exact path="/Symbiosys" element={<Symbiosys/>}></Route>
          <Route exact path="/GSA" element={<GSA/>}></Route>
          <Route exact path="/AcademiaEngagement" element={<AcademiaReltion/>}></Route>
          <Route exact path="/Membership" element={<Membership/>}></Route>
          <Route exact path="/MarqueeViews" element={<LeadershipThought/>}></Route>
          <Route exact path="/news" element={<News/>}></Route>
          <Route exact path="/Products" element={<Corplink/>}></Route>
          <Route exact path="/Corplink" element={<Corplink/>}></Route>
          <Route exact path="/Sales" element={<Sales/>}></Route>
          <Route path="*" element={<PagenotFound/>}></Route>           
        </Routes>
      <Footer/>     
    </>
  );
}

export default App;
