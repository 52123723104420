import React from 'react'
//import Sendquery from './Sendquery'

function Locations() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 Locations-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Sales</h1>
                        {/*<p className='blockquote'>Achieves Best-In-Class Power Performance, Area, Schedule</p>*/}
                    </div>                    
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 mb-5 text-center'>
                    <p>MarqueeSemi’s deep silicon application and design expertise has enabled the company to support on technical sales and support for Silicon Design companies. The company currently represents several silicon companies around the world to distribute their products and support licensing their IPs for silicon designs.
                    </p>
                    {/*<p>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” </p>*/}
                </div>
                <div className='col-md-2 offset-md-5 text-center mb-2'>
                   <a href='https://signatureip.ai' target='_blank'><img src="./images/partner1.png" alt="Marquee Semiconductor" className="img-fluid"/></a> 
                </div>
                
            </div>
            
            
        </div>
    </>
  )
}

export default Locations